.input-group {
  flex-wrap: nowrap; // For form validation feedback
}
// Make the div behave like a button
.actionbar-group{
  position: static;
}

// md and bellow apply text-nowrap to all columns of a table
@include media-breakpoint-down(md) {
  .table-responsive th,
  .table-responsive td {
    white-space: nowrap !important;
  }
}

.form > .alert {
  margin-bottom: 0;
}

// remove the error icon from input
// this is needed for consistency
.form-control:invalid,
.form-control.is-invalid {
  background-image: unset !important;
  padding-right: unset !important;
}

.action-bar-dropdown-icon.dropdown-toggle::after {
  content: unset !important;
}

.tribute-container {
  ul {
    padding-left: 0;
  }
}

select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;

  .select2-selection {
    background: #f6f7f9;
    box-shadow: none;
  }

  .select2-selection__arrow,
  .select2-selection__rendered .select2-selection__clear {
    display: none;
  }
}

select.select2-hidden-accessible.is-invalid + .select2-container {
  .select2-selection {
    border-color: $form-feedback-invalid-color;
  }
}

.select2-results__option {
  white-space: preserve-spaces;
}

td {
  white-space: preserve-spaces;
}
@media print {
  .disable-printing {
    display: none
  }
}

// card deck column
.card-deck-column {
  display: flex;
  flex-direction: column;
  flex:  1 0 !important;
}

@include media-breakpoint-up(sm) {
  .card-deck-single-column {
    width: 50%;
  }
}

// Make Ron Happy
@include media-breakpoint-up(sm) {
  .page-sidebar {
    .list-group-item-body {
      padding: 0.3rem 0;
    }
  }
}


.deep-link {
  transition: all 0.5s ease;
  background-color: $yellow;
}

.multiline {
  white-space:pre-wrap;
  word-wrap:break-word;
}

td.fit-content {
  width: 0;
  min-width: fit-content;
  white-space: nowrap !important;
  text-align: right !important;
}

th.fit-content {
  width: 0;
  min-width: fit-content;
  padding-left: 1rem !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
}

.modal-backdrop.show {
  opacity: 0.15;
}

.document-viewer {
  .card-body {
    position: relative;
  }
}

// Custom text colors for different backgrounds by modes

.badge {
  color: white; // White for light mode
}

.bg-purple {
  color: #d6d8e1;
  .list-group-item-title {
    color: #d6d8e1;
  }
}

.bg-warning, bg-green, .bg-orange, .bg-yellow {
  color: #363642;
  h5.list-group-item-title {
    color: #363642;
  }
}

body[data-skin='dark'] {
  .badge {
    color: #d6d8e1; // A little less bright white for dark mode
  }

  .bg-gray, .bg-warning, .bg-orange, .bg-yellow {
    color: #363642;
    h5.list-group-item-title {
      color: #363642;
    }
  }
}


.tribute-container {
  bottom: 0;
  left: 0;
  height: auto;
  overflow: auto;
  display: block;
  z-index: 999999;
}

/* ===== Scrollbar CSS ===== */
/* Chrome, Edge, and Safari */

:active::-webkit-scrollbar-thumb,
:focus::-webkit-scrollbar-thumb,
:hover::-webkit-scrollbar-thumb {
  border-radius: 6px;
  visibility: visible;
  transition: background-color .8s linear, width .8s ease-in-out;
  -webkit-transition: background-color .8s linear, width .8s ease-in-out;
}
::-webkit-scrollbar-thumb:active {
  background-color: #999;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}


// Pivot

.pivot-table {
  .pivot-column-field, .pivot-row-field {
    background-color: $gray-100;
  }
  .pivot-column-field, .pivot-row-field, .pivot-data-cell {
    border-color: $gray-400;
  }
}
